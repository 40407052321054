button {
    font-size: 20px;
    padding: 10px;
    padding-bottom: 5px;
    text-align: center;
    width: 300px;
    max-width: 100%;
    border-radius: 0px;
    border: none;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 100;
    outline: none;
    cursor: pointer;
    font-family: $name;
    margin: 5px 0px;

    .button_icon {
        font-size: 26px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px; } }

button:disabled {
    background-color: #a7a7a7;
    color: $quinary;
    border: 1px solid $quinary;
    cursor: not-allowed; }

.primary {
    background-color: $primary;
    color: $quarternary;
    border: 1px solid $primary; }

.secondary {
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary; }

.danger {
    background-color: $danger;
    color: $quarternary;
    border: 1px solid $danger; }

button.full {
    width: 100%; }
