.language_radio {
    display: inline-block;
    font-family: $sans;
    width: calc(100% - 2px);
    border: 1px solid $primary;
    font-size: 24px;
    background-color: $quarternary;
    text-align: left;

    .item {
        padding: 10px;
        line-height: 40px;
        position: relative;
        cursor: pointer;

        .item-icon {
            width: 40px;
            vertical-align: bottom; }

        .tick {
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            right: 10px; } }

    hr {
        margin: 3px 20px;
        height: 1px;
        border: none;
        background-color: #c4c4c4; } }
