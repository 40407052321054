.print_wrap {
    width: calc(100% - 15px);
    overflow: hidden;
    position: absolute;
    height: 100%;

    .print {
        height: 100%;
        opacity: 0.3; } }

.search_bar {
    width: calc(100% - 40px);
    font-size: 24px;
    border-radius: 20px;
    border: none;
    line-height: 40px;
    outline: none;
    padding-left: 40px;
    margin-bottom: -50px;

    &::-webkit-input-placeholder {
        font-family: $sans; } }

.search_icon {
    font-size: 24px;
    position: absolute;
    margin-top: 9px;
    margin-left: 7px; }

.menu {
    position: relative;
    max-width: 680px;
    width: 300px;
    display: inline-block;
    text-align: left;
    margin: 0px 0px;
    padding: 20px;
    background-color: $quarternary;
    // box-shadow: 0px 0px 20px 1px #00000080
    height: calc(100vh - 80px);
    overflow-y: auto;
    overflow-y: overlay;
    text-align: center;
    vertical-align: top;

    hr {
        border: none;
        height: 1px;
        background-color: $primary;
        margin: 40px 0px; }

    .item {
        font-family: $name;
        font-size: 24px;
        // letter-spacing: 1px
        margin: 20px 0px;
        text-align: left;
        cursor: pointer;
        // font-weight: 600

        &.small {
            font-size: 16px;
            color: $quinary;
            font-weight: 600;
            margin: 10px 0px; } }

    .menu_icon {
        display: inline-block;
        width: 40px;
        vertical-align: middle;
        font-size: 26px;

        .te_icon {
            width: 24px; } }

    .logo {
        width: 80px; }

    .heading {
        font-size: 32px;
        font-family: 'Quicksand', sans-serif;
        color: black;
        margin-bottom: 20px;
        letter-spacing: 2px;
 } }        // font-weight: 600

.home {
    text-align: center;
    position: relative;

    .page_heading {
        color: $quarternary;
        font-family: $name;
        font-size: 50px;
        margin-bottom: 40px;
        text-shadow: 0px 5px 10px black;
        text-transform: capitalize;
        padding-top: 60px;
 }        // font-weight: 500

    .menu, .body, .post, .account {
        text-align: left; }

    .header {
        position: fixed;
        width: 660px;
        max-width: calc(100% - 20px);
        text-align: center;
        color: $quarternary;
        background-color: $secondary;
        padding: 10px;
        padding-bottom: 0px;
        margin-top: -20px;
        margin-left: -20px;
        z-index: 3;
        box-shadow: 0px 0px 20px 1px #00000080;
        font-family: $sans;
        font-size: 32px;

        .help {
            float: left;
            margin-top: 3px; }

        .burger {
            float: right;
            margin-top: 0px;
            font-size: 40px;
            margin-bottom: -4px; } } }

.encompass {
    position: relative;

    .plus_button {
        height: 70px;
        width: 70px;
        border-radius: 50%;
        // border: 1px solid $secondary
        background-color: $quarternary;
        color: $primary;
        box-shadow: 0px 10px 20px 1px #000000;
        position: absolute;
        bottom: 80px;
        right: 20px;
        font-size: 70px;
        line-height: 70px;
        cursor: pointer;
        transition: 0.2s ease-in-out all;
        z-index: 50; } }
