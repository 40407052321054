.endorsement {
    background-color: $quarternary;
    border-radius: 5px;
    padding: 10px;
    padding-bottom: 5px;
    display: inline-block;
    margin: 5px;
    border-bottom: 5px solid $primary;
    cursor: pointer;

    .user {
        font-weight: 600;
        text-transform: capitalize; } }
