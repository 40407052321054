.auth-body {
    max-width: 680px;
    width: calc(100% - 40px);
    display: inline-block;
    text-align: left;
    // padding: 20px
    background-color: $secondary;
    margin: 20px 0px;
    box-shadow: 0px 0px 20px 1px #00000080;
    max-height: calc(100vh - 40px);
    position: relative;
    overflow: hidden;
    z-index: 0;

    .auth_languages {
        margin-bottom: -65px;

        .language_icon {
            width: 35px;
            margin: 0px 5px;
            padding: 2px;
            border-radius: 50%;
            border: 2px solid transparent; } }

    .print {
        position: absolute;
        height: 100vh;
        z-index: -1;
        background-repeat: repeat;
        opacity: 0.4; } }

// .auth-body::after
//     // display: block
//     content: ''
//     position: absolute
//     top: 0
//     left: 0
//     right: 0
//     bottom: 0
//     background-repeat: repeat
//     background-image: url("../../img/print.svg")
//     background-size: auto 100vh
//     // height: 706px
//     border: 10px solid red
//     z-index: -1
//     opacity: 0.4

.auth {
    font-size: 50px;
    color: $primary;
    text-align: center;
    overflow-y: auto;
    overflow-y: overlay;
    max-height: calc(100vh - 80px);
    padding: 20px;

    .logo {
        width: 100px;
        margin-bottom: 25px; }

    .login-form {
        background-color: $quarternary;
        padding: 40px;
        vertical-align: middle;
        border-radius: 5px;
        text-align: center;
        font-family: $sans;
        padding-top: 50px;

        .error {
            font-size: 15px;
            color: $danger;
            height: 15px;
            margin-top: 0px;
            margin-bottom: -10px; }

        input, select, textarea {
            font-size: 20px;
            padding: 10px;
            border: 1px solid #c4c4c4;
            border-bottom: 1px solid $primary;
            display: inline-block;
            width: 278px;
            max-width: calc(100% - 22px);
            transition: 0.2s ease-in-out all;
            height: 23px;
            font-family: $sans;
            outline: none; }

        select {
            height: 45px;
            width: 298px;
            max-width: 100%; }

        .phone_input {
            width: 199px;
            max-width: calc(100% - 101px);
            margin-left: -1px; }

        .country_codes {
            font-size: 15px;
            width: 80px;
            height: 45px;
            vertical-align: bottom; }

        textarea {
            height: 90px;
            margin-top: 10px;
            margin-bottom: -10px;

            /* Chrome, Safari, Edge, Opera */ }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0; }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield; }

        input:focus {
            // border-bottom: 1px solid green
            outline: none; }

        hr {
            margin-bottom: 10px;
            border: none;
            height: 1px;
            background-color: $primary; }

        .small_text {
            font-size: 15px;
            color: black;
            margin-top: 30px;
            display: inline-block;
            cursor: default; }

        .no_margin {
            margin-top: 10px;
            margin-bottom: -5px;
            display: block; }

        .link {
            color: $primary;
            font-weight: 700;
            cursor: pointer;
            margin-top: 0px; }

        label {
            display: inline-block;
            vertical-align: middle;
            font-size: 15px;
            color: black; }

        .checkbox {
            display: inline-block;
            width: 18px;
            vertical-align: middle;
            margin: 0px;
            margin-right: 5px; } } }

@media (max-width: 680px) {
    .auth-body {
        margin: 0px;
        height: 100vh;
        width: 100vw;
        max-height: 100vh; }

    .auth {
        max-height: calc(100vh - 40px); } }
