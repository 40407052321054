.video-js {
    max-width: 100%;
    width: 680px;
    // max-height: auto
    height: 320px;
    border-radius: 5px;

    .vjs-big-play-button {
        top: calc(50% - 50px);
        left: calc(50% - 50px);
        border-radius: 50%;
        height: 100px;
        width: 100px;
        border: none;
        line-height: 100px;
        font-size: 50px;
        color: $primary;
        background-color: $secondary; }

    .vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal {
        display: none; } }

.vjs-theme-city {
    .vjs-play-progress {
        background-color: $primary; }

    .vjs-control-bar {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px; }

    .vjs-volume-panel {
        display: none; }

    .vjs-remaining-time {
        max-width: 50px; }

    .vjs-play-control, .vjs-picture-in-picture-control, .vjs-fullscreen-control {
        outline: none; } }

.video-box {
    background-color: $quarternary;
    border-radius: 5px;
    border: 1px solid $quarternary;
    box-shadow: 0px 0px 20px 1px #00000080;
    position: relative;
    z-index: 2;

    .description {
        font-size: 18px;
        margin-top: 10px;
        padding: 0px 10px;
        font-family: 'Amazon Ember', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

        .user {
            display: inline-block;
            font-family: 'Amazon Ember', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
            font-size: 20px;
            font-weight: 600;
            text-transform: capitalize;
            cursor: pointer; } }

    .interaction {
        margin: 10px 0px;

        .button {
            display: inline-block;
            width: calc((100% - 2px) / 3);
            font-size: 18px;
            text-align: center;
            line-height: 18px;
            cursor: pointer;
            font-family: 'Amazon Ember', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

            .icon {
                font-size: 25px;
                vertical-align: bottom;
                display: inline-block; } }

        .button:nth-child(2) {
            border-left: 1px solid $secondary;
            border-right: 1px solid $secondary; } } }

.video-js .vjs-tech {
    border-radius: 5px; }
