@media (min-width: 1240px) {
    .encompass {
        width: 1200px; }

    .body {
        max-width: 820px;
        text-align: center; }

    .body_wrap {
        max-width: 860px; }

    .home {
        max-width: 680px;
        width: 680px;
        display: inline-block; } }

@media (min-width: 1440px) {
    .encompass {
        width: 1400px; }

    .body {
        max-width: 1020px;
        text-align: center; }

    .body_wrap {
        max-width: 1060px; }

    .home {
        max-width: 680px;
        display: inline-block; } }

@media (max-width: 1100px) {
    @media (min-width: 900px) {
        .menu {
            width: 200px!important;

            .search_bar {
                font-size: 22px; }

            .item {
                font-size: 22px; } }

        .encompass {
            width: 960px; } } }

@media (max-width: 1000px) {
    .menu {
        display: none; }

    .encompass {
        width: 680px; } }

@media (min-width: 1001px) {
    .home {
        .header {
            display: none; } }

    // .body
    //     padding-top: 60px

    .main_logo {
        margin-top: 30px; }

    .encompass {
        .plus_button {
            bottom: 20px; } } }

@media (max-width: 1001px) {
    .encompass {
        margin: 0px;
        height: 100vh;
        width: 680px; }

    .body {
        margin: 0px;
        height: calc(100vh - 20px); }

    .body_wrap {
        height: 100vh; }

    .home .page_heading {
        font-size: 40px; }

    .main_logo {
        padding-top: 60px; }

    .body_wrap {
        .footer_menu {
            display: block; } }

    .padding_bottom {
        padding-bottom: 80px; }

    .tile_wrap {
        width: calc((100% - 20px) / 2); }

    .account {

        .user_pic {
            display: block;
            text-align: center;
            margin-bottom: -20px; }

        #bio {
            .content {
                font-size: 20px; } }

        #username {
            display: block;
            vertical-align: middle;
            text-align: center;
            padding-left: 0px;

            .heading {
                font-size: 40px; } } }

    .search_bar {
        width: calc(100% - 150px);
        font-size: 20px;
        border-radius: 20px;
        border: none;
        line-height: 30px;
        outline: none;
        padding-left: 40px;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: calc(50% + 1px); }

    .search_icon {
        font-size: 24px;
        position: absolute;
        margin-top: 7px;
        margin-left: 49px;
        z-index: 3;
        color: black; }

    .skill_heading {
        font-size: 18px; } }

@media (max-width: 520px) {
    .filter_wrap {
        .feed_filter {
            font-size: 16px; } } }

@media (max-width: 597px) {
    // .auth
    //     .login-form
    //         label
 }    //             margin-top: -30px
