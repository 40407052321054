.settings {

    .section {
        border-bottom: 1px solid $primary;
        padding-bottom: 30px;
        margin-bottom: 20px;

        .section_icon {
            font-size: 35px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px; }

        .heading {
            font-family: $name;
            color: $quarternary;
            font-size: 30px;
            text-align: left;
            margin-bottom: 20px;
            text-shadow: 0px 5px 10px black; }

        .settings_check {
            display: inline-block;
            vertical-align: middle;
            width: 30px;
            height: 30px;
            background-color: $quarternary;
            border: 1px solid $primary;
            text-align: center;

            .settings_tick {
                padding: 8px; } }

        .check_label {
            display: inline-block;
            vertical-align: middle;
            margin-left: 20px;
            font-size: 18px;
            color: $quinary;
            font-family: $sans; } }

    .section:last-child {
        border: none; } }
