.filter_btns {
    font-size: 18px;
    font-family: $sans;
    color: $primary;

    .filter_btn {
        display: inline-block;
        width: calc((100% - 55px) / 2);
        margin: 5px;
        font-size: 16px;
        border: 1px solid $primary;
        line-height: 30px;
        text-align: left;
        padding-left: 5px;
        position: relative;

        .plus {
            position: absolute;
            top: 3px;
            right: 2px; } } }

.no_results {
    color: $quarternary;
    font-size: 30px;
    font-family: $name;
    margin-top: 20px; }
