.filter_wrap {
    .feed_filter {
        display: inline-block;
        width: calc((100% - 30px) / 3);
        font-size: 24px;
        font-family: $name;
        color: $quarternary;
        cursor: pointer;
        border-radius: 5px;
        padding: 5px;
        text-shadow: 0px 5px 10px black; } }
