.help {

    .help_section {
        text-align: center;
        margin-bottom: 30px;

        .heading {
            font-size: 30px;
            color: $quarternary;
            font-family: $name;
            text-align: left;
            margin-bottom: 20px;

            .help_icon {
                font-size: 26px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;

                .te_icon {
                    width: 26px; } } }

        .help_video {
            width: 200px;
            display: inline-block;
            vertical-align: middle;
            border: 5px solid black;
            border-top: 20px solid black;
            border-bottom: 10px solid black;
            border-radius: 7px;
            position: relative;

            .phone_line {
                height: 4px;
                background-color: $quinary;
                border-radius: 5px;
                width: 40px;
                position: absolute;
                z-index: 1;
                top: -12px;
                left: calc(50% - 25px); }

            .phone_dot {
                height: 4px;
                background-color: $quinary;
                border-radius: 5px;
                width: 4px;
                position: absolute;
                z-index: 1;
                top: -12px;
                left: calc(50% + 20px); }

            .video-js .vjs-tech, .vjs-theme-city .vjs-control-bar {
                border-radius: 0px; }

            // .phone_button
            //     height: 30px
            //     background-color: $quinary
            //     border-radius: 50%
            //     width: 30px
            //     position: absolute
            //     z-index: 1
            //     bottom: -49px
            //     left: calc(50% + 2px)
 }            //     transform: translate(-50%, -50%)

        .help_description {
            display: inline-block;
            width: 300px;
            text-align: left;
            vertical-align: middle;
            margin-left: 20px;
            color: $quinary;
            font-size: 18px;
            font-family: $sans;
            padding: 20px 0px 0px 0px; } }

    hr {
        height: 1px;
        border: none;
        background-color: $quinary;
        margin-bottom: 20px; } }
