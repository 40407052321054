.language_select, .skill_select {
    font-family: $sans;
    font-size: 20px;
    border: 1px solid #c4c4c4;
    border-bottom: 1px solid $primary;
    display: inline-block;
    width: 298px;
    max-width: calc(100% - 2px);
    transition: 0.2s ease-in-out all;
    height: 45px;
    font-family: $sans;
    outline: none;
    background-color: white;
    color: #7e7e7e;
    text-align: left;
    margin-top: 5px;
    cursor: pointer;
    position: relative;

    .sub_heading {
        text-align: center;
        margin-top: 10px; }

    .arrow_down {
        transform: rotate(45deg);
        border-bottom: 2px solid $quinary;
        border-right: 2px solid $quinary;
        position: absolute;
        width: 5px;
        height: 5px;
        right: 10px;
        top: calc(50% - 5px); }

    .placeholder {
        margin: 10px;

        .selected {
            color: black; }

        .item-icon {
            width: 25px;
            vertical-align: bottom; } }

    #language_dropdown, #skill_dropdown {
        overflow: hidden;
        transition: 0.2s ease-in-out max-height;
        background-color: white;
        width: 298px;
        margin-left: -1px;
        border-left: 1px solid #c4c4c4;
        border-right: 1px solid #c4c4c4;
        max-width: 100%;

        .item {
            padding: 10px;
            color: black; }

        .item-icon {
            width: 25px;
            vertical-align: bottom; } }

    #skill_dropdown {
        width: 300px; } }
