.user_icon {
    width: calc(100% - 20px);
    background-color: $quarternary;
    padding: 10px;
    border-radius: 5px;
    font-size: 24px;
    text-transform: capitalize;
    font-family: $name;
    cursor: pointer;
    margin: 20px 0px;

    .icon {
        font-size: 30px; } }
