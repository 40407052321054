#outer-container {
  height: 100vh; }

.slidemenu {
  max-width: 680px;
  width: 250px;
  display: inline-block;
  text-align: left;
  margin: 0px 0px;
  padding: 20px;
  background-color: $quarternary {
 }    // box-shadow: 0px 0px 20px 1px #00000080
  height: calc(100vh - 40px);
  overflow-y: auto;
  text-align: center;
  vertical-align: top;
  outline: none;

  .x {
    position: absolute;
    font-size: 50px;
    right: 30px;
    top: 10px; }

  hr {
    border: none;
    height: 1px;
    background-color: $primary;
    margin: 40px 0px; }

  .item {
    font-family: $name;
    font-size: 20px;
    // letter-spacing: 1px
    margin: 20px 0px;
    text-align: left;
    cursor: pointer;
    // font-weight: 600

    &.small {
      font-size: 16px;
      color: $quinary;
      font-weight: 600;
      margin: 10px 0px; } }

  .menu_icon {
    display: inline-block;
    width: 40px;
    vertical-align: middle;
    font-size: 22px;

    .te_icon {
      width: 20px; } }

  .logo {
    width: 80px; }

  .heading {
    font-size: 30px;
    font-family: 'Quicksand', sans-serif;
    color: black;
    margin-bottom: 20px;
    letter-spacing: 2px;
 }    // font-weight: 600

  /* Position and sizing of burger button */ }
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
  display: none; }

/* Color/shape of burger icon bars */
.bm-burger-bars {
  display: none; }

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  display: none; }

/* Color/shape of close button cross */
.bm-cross {
  background: black; }

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 290px!important;
  margin-right: -1px; }

/* General sidebar styles */
.bm-menu {}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47; }

/* Wrapper for item list */
.bm-item-list {
  color: black; }

/* Individual item */
.bm-item {
  display: inline-block; }

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3); }
