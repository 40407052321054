// Import variable files
@import "components/variables";
@import "components/scrollbar/scrollbar";

// Import styles
@import "components/auth/auth";
@import "components/video/video";
@import "components/loading/loading";
@import "components/feed/post";
@import "components/feed/upload";
@import "components/main/main";
@import "components/account/account";
@import "components/menu/menu";
@import "components/positions/positions";
@import "components/main/footer";
@import "components/main/hover";
@import "components/misc/language_select";
@import "components/misc/language_radio";
@import "components/settings/settings";
@import "components/account/position_tile";
@import "components/account/switch";
@import "components/search/user_icon";
@import "components/search/search";
@import "components/feed/comments";
@import "components/saved/saved";
@import "components/no_wifi/no_wifi";
@import "components/modal/modal";
@import "components/main/buttons";
@import "components/user/endorsements";
@import "components/feed/filter";
@import "components/notifications/notifications";
@import "components/user/skill";
@import "components/coming_soon/coming_soon";
@import "components/t_and_c/policy";
@import "components/install/install";
@import "components/audio/audio";
@import "components/help/help";

body {
    background-color: $primary!important;
    text-align: center;
    margin: 0px;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }

.encompass {
    box-shadow: 0px 0px 20px 1px #00000080;
    height: calc(100vh - 40px);
    margin: 20px 0px;
    display: inline-block;
    width: 1060px;
    max-width: 100%;
    overflow: hidden; }

.body, .body_wrap {
    max-width: 680px;
    width: calc(100% - 40px);
    display: inline-block;
    text-align: left;
    padding: 20px;
    background-color: $secondary;
    overflow-y: auto;
    overflow-y: overlay;
    height: calc(100vh - 80px);
    margin: 00px 0px;
    vertical-align: top; }

.body_policy {
    background-color: $quarternary;

    button {
        margin-bottom: 20px; } }

.body_wrap {
    padding: 0px;
    width: calc(100% + 1px);
    height: calc(100vh - 40px);
    max-width: 720px;
    position: relative;
    overflow: hidden; }

@import "components/main/responsive";
