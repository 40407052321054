.account {
    font-family: $sans;
    // padding-top: 60px

    .heading {
        color: $primary;
        font-size: 30px; }

    .content {
        color: $quarternary;
        font-size: 24px; }

    .section {
        margin-bottom: 20px; }

    .user_pic {
        font-size: 150px;
        display: inline-block;
        vertical-align: middle; }

    #bio {
        text-align: left;
        margin-top: 10px; }

    #skills {
        padding: 10px;
        border: 1px solid $quinary;

        .content {
            font-size: 18px;
            color: $quinary; } }

    #username {
        display: inline-block;
        vertical-align: middle;
        padding-left: 20px;

        .heading {
            font-size: 40px; } }

    .modal_button {
        width: 100%;
 }        // margin-bottom: 20px

    .follower_wrap {
        .follower_set {
            display: inline-block;
            width: calc(100% - 11px);
            font-size: 26px;
            color: $quinary;
            line-height: 30px;

            &.left {
                text-align: right;
                padding-right: 10px;
                border-right: 1px solid $primary; }

            &.right {
                text-align: left;
                padding-left: 10px; } }

        .modal_button {
            text-align: right;
            width: 50%;
            margin-bottom: 0px;
            cursor: pointer;

            &:nth-child(2n) {
                text-align: left; } }

        .follower {
            text-align: left;
            font-size: 20px;
            line-height: 50px;
            border-bottom: 1px solid $quinary;
            width: 300px;
            max-width: 100%;
            display: inline-block;

            &:last-child {
                border-bottom: none; }

            .username {
                width: 50%;
                display: inline-block;
                vertical-align: middle; }

            button {
                width: 50%;
                margin: 10px 0px;
                font-size: 16px;
                display: inline-block;
                vertical-align: middle; } } } }
