.upload {

    .hide_html_upload {
        display: none; }

    .file_upload_wrap {
        border: 1px solid $quarternary;
        padding: 20px;
        width: 100%;
        max-width: calc(100% - 40px);
        margin-bottom: 30px;

        .file_upload {
            font-family: $sans;
            color: $quarternary;

            .upload_icon {
                color: black;
                font-size: 50px;
                display: inline-block;
                padding: 10px;
                padding-bottom: 0px;
                border-radius: 5px;
                background-color: $quarternary;
                vertical-align: middle; }

            .upload_content {
                display: inline-block;
                padding-left: 10px;
                font-size: 22px; } } }

    input, textarea {
        font-size: 20px;
        padding: 10px;
        border: 1px solid #c4c4c4;
        border-bottom: 1px solid $primary;
        display: inline-block;
        width: 100%;
        max-width: calc(100% - 22px);
        transition: 0.2s ease-in-out all;
        height: 23px;
        font-family: $sans; }

    input {
        margin-bottom: 20px; }

    textarea {
        outline: none;
        height: 90px;
        background-color: $quarternary; }

    .description_acc {
        color: $primary;
        font-family: $name;
        cursor: pointer;
        font-size: 20px;
        line-height: 20px; }

    .extend {
        transition: 0.2s ease-in-out all;
        overflow: hidden;
        margin-bottom: 20px;
        padding-top: 10px; }

    .error {
        color: $danger;
        font-family: $sans;
        font-weight: 700; } }
