.tiles {
    border: 1px solid $quinary;
    padding: 10px;
    margin-bottom: 20px;

    .tiles_heading {
        color: $quinary;
        font-size: 18px;
        padding-bottom: 10px; } }

.tile_wrap {
    display: inline-block;
    width: calc((100% - 30px) / 3);
    margin: 5px 5px;

    .video_wrapper {
        width: 300px;
        max-width: calc(100% - 20px);
        display: inline-block; }

    video {
        margin-bottom: -4px;
        z-index: 20; }

    .tile {
        background-color: $quarternary;
        display: inline-block;
        width: 100%;
        text-align: center;
        height: 100%;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        .tile_description {
            background-color: #00000090;
            z-index: 2;
            position: absolute;
            bottom: 0px;
            width: 100%;
            color: white;
            height: 25px;
            line-height: 25px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }

        .tile_content {
            vertical-align: middle;
            padding-bottom: 10px;

            .title {
                font-size: 20px;
                text-overflow: ellipsis;
                max-height: 25px;
                overflow: hidden; }

            .location {
                font-size: 18px;
                margin-bottom: 10px; }

            .description {
                font-size: 12px;
                text-align: left;
                text-overflow: ellipsis; } } }

    .position_tile {
        height: 100px; } }
