.home {
    .post {
        position: relative;
        margin: 20px 0px;
        color: black;

        .bubble_icon {
            position: absolute;
            top: 8px;
            right: 2px;
            color: $quarternary;
            font-size: 30px;
            z-index: 2; }

        .report_icon {
            position: absolute;
            top: 8px;
            left: 7px;
            color: $quarternary;
            font-size: 30px;
            z-index: 2; }

        .report_option {
            border: 1px solid #c4c4c4;
            line-height: 30px;
            text-align: left;
            padding-left: 10px;
            width: calc(100% - 60px);
            max-width: 290px;
            margin-top: -1px;
            display: inline-block;
            background-color: white;
            position: relative;
            cursor: pointer;

            .tick {
                position: absolute;
                right: 13px;
                top: 8px; } }

        .extend {
            overflow: hidden;
            transition: 0.2s ease-in-out all;

            textarea {
                font-size: 16px;
                outline: none; } } } }
