.toggle {

    .toggle-label {
        margin-right: 10px;
        display: inline-block; }

    .react-toggle--focus .react-toggle-thumb {
        box-shadow: none; }

    .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
        box-shadow: none; }

    .react-toggle {
        vertical-align: sub; } }
