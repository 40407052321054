.construction {
    .construction_icon {
        font-size: 200px;
        color: $primary;
        background-color: $quarternary;
        display: inline-block;
        border-radius: 50%;
        width: 210px;
        height: 250px;
        padding: 40px 40px 0px 40px;
        box-shadow: 0px 10px 20px 1px #000000; }

    .content {
        margin-top: -160px;
        font-size: 30px;
        font-weight: 900;
        font-family: $name;
        color: black; } }
