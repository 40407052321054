// Colours
$primary: #cba42d;
$secondary: #292929;
$quarternary: #e9e9e9;
$quinary: #5a5a5a;
$danger: #970000;

@font-face {
    font-family: 'hanifi';
    src: url(../fonts/NotoSansHanifiRohingya-Regular.ttf) format('truetype'); }

// fonts
$sans: var(--sans);
// $serif:
$name: var(--name);
