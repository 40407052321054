.notification {
    text-align: left;
    font-family: $sans;
    font-size: 20px;
    padding: 10px;
    border: 1px solid $quarternary;
    color: $quarternary;
    cursor: pointer;
    margin-top: -1px;
    overflow: hidden;

    .note_icon {
        display: inline-block;
        font-size: 30px;
        vertical-align: middle;
        margin-bottom: -5px;
        width: 40px; }

    .note_content {
        display: inline-block;
        vertical-align: middle;
        width: calc((100% - 20px) - 40px); }

    .date {
        font-size: 14px;
        color: rgba($quarternary, 0.6);
        margin: 3px 0px; }

    .description {
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 20px;
        white-space: nowrap; } }

.no_notifications {
    color: $quarternary;
    font-family: $name;
    font-size: 20px; }
