.body_wrap {
    .footer_menu {
        width: calc(100% + 1px);
        height: 50px;
        background-color: transparent;
        // box-shadow: 0px 0px 20px 1px #000000
        position: absolute;
        bottom: 0px;
        right: 0px;
        display: none;
        // padding: 10px 0px
        transition: 0.2s ease-in-out all;
        z-index: 100;

        .footer_item {
            text-align: center;
            display: inline-block;
            width: calc(100% / 4);
            line-height: 40px;
            font-size: 30px;
            text-shadow: 0px 5px 10px #00000040;
            padding-top: 10px;
            padding-bottom: 7px;
            margin-top: -1px;

            .te_icon {
                width: 26px;
                margin-top: 4px; } }

        // .footer_item:nth-child(2)
        //     border-left: 1px solid $secondary
        //     border-right: 1px solid $secondary

        // .footer_item:nth-child(3)
 } }        //     border-right: 1px solid $secondary
