.skill_wrap {
    margin: 10px 0px;

    .no_skills {
        color: $quinary;
        text-align: center; }

    .skill {
        border-bottom: 1px solid $quinary;
        padding: 10px;
        color: $quarternary;
        font-size: 20px;

        .modal_button {
            width: 150px;

            .button_icon {
                font-size: 20px; }

            button {
                font-size: 16px; } }

        .modal {
            color: black;
            font-size: 16px; }

        .skill_heading {
            width: calc(100% - 160px);
            display: inline-block;
            vertical-align: middle;
            padding-right: 10px; }

        .endorsement {
            color: black; }

        &:last-child {
            border-bottom: none; } } }
