.no_wifi_wrap {

    .encompass {
        width: 680px; } }

.no_wifi {
    text-align: center;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    .no_wifi_heading {
        color: $quarternary;
        font-family: $name;
        font-size: 30px; }

    .policy_heading {
        margin-top: 20px;
        color: black; }

    .no_wifi_content {
        margin-top: 20px;
        text-align: left;

        .no_wifi_sub_heading {
            color: black;
            font-size: 26px;
            font-family: $name;
            margin-bottom: 10px; }

        .no_wifi_normal {
            color: $quinary;
            font-family: $sans;
            margin-bottom: 20px; } }

    .no_wifi_icon {
        font-size: 200px;
        color: $quinary;
        margin-bottom: -25px; }

    button {
        font-size: 20px;
        padding: 10px;
        padding-bottom: 5px;
        text-align: center;
        width: 300px;
        max-width: 100%;
        border-radius: 0px;
        border: none;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-weight: 100;
        outline: none;
        cursor: pointer;
        font-family: $name; }

    .primary {
        background-color: $primary;
        color: $quarternary; } }
