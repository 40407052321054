.position {
    background-color: $quarternary;
    border-radius: 5px;
    padding: 10px;
    text-align: left;
    font-family: $sans;
    margin: 20px 0px;
    position: relative;
    box-shadow: 0px 0px 20px 1px #00000080;

    .title {
        font-size: 30px;
        font-family: $name; }

    .location {
        font-size: 20px;
        color: $primary;
        font-weight: 700;
        margin-bottom: 20px; }

    .description {
        font-size: 18px;

        .user {
            display: inline-block;
            font-family: $sans;
            font-size: 20px;
            font-weight: 600;
            text-transform: capitalize; } }

    .audio_wrap {
        text-align: center;

        audio {
            margin-bottom: 10px;
            outline: none; } }

    .bubble_icon {
        position: absolute;
        top: 8px;
        right: 2px;
        font-size: 30px; }

    .apply {
        background-color: $primary;
        color: $quarternary;
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: -10px;
        margin-top: 20px;
        padding: 10px;
        padding-bottom: 5px;
        text-align: center;
        font-size: 20px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        cursor: pointer; }

    .button_icon {
        font-size: 30px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px; } }
