.logo {
    width: 100px;
    margin-bottom: 10px;
    vertical-align: middle; }

.apple_share, .firefox_share {
    color: #388199;
    font-size: 24px;
    display: inline-block;
    vertical-align: middle; }

.firefox_share {
    margin-bottom: -6px; }

.install_icon {
    font-size: 100px; }

.install_reason {
    font-size: 20px;
    margin-bottom: 20px;
    padding: 0px 20px; }

.install_banner {
    background-color: #c4c4c4;
    font-size: 20px;
    margin-bottom: -20px;
    line-height: 50px; }
