.policy {
    padding: 20px;
    max-width: 680px;
    display: inline-block;

    .policy_heading {
        font-size: 30px;
        font-family: $name;
        color: black; }

    .policy_content {
        margin-top: 20px;
        text-align: left;

        .policy_sub_heading {
            color: black;
            font-size: 26px;
            font-family: $name;
            margin-bottom: 10px; }

        .policy_normal {
            font-size: 18px;
            color: $secondary;
            font-family: $sans;
            margin-bottom: 30px;

            a {
                color: $primary;
                text-decoration: none;

                &:hover {
                    border-bottom: 1px solid $primary; } } } } }
