.audio_text {
    display: inline-block;
    width: calc(100% - 70px);
    vertical-align: middle; }

.audio {
    border: 3px solid $quarternary;
    display: inline-block;
    width: 40px;
    height: 38px;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    font-size: 34px;
    padding-top: 2px;
    margin-left: 20px; }
