/* width */
::-webkit-scrollbar {
    width: 10px; }

/* Track */
::-webkit-scrollbar-track {
    background: transparent; }

/* Handle */
::-webkit-scrollbar-thumb {
    background: $quinary; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; }
